<template>
    <div class="page">
        <to-history :prop-message="title"></to-history>
        <div class="search_wap">
            <div class="a-inline-block">
                <a-input-search v-model="name" class="sInput" placeholder="请输入员工姓名" @search="onSearch" />
            </div>
        </div>
        <div class="lists" v-for="(item, index) in data" :key="index">
            <div class="list_row">
                    <div class="list_lable">员工姓名：<span>{{ item.name }}</span></div>
                    <div class="list_lable">所属部门：<span>{{ item.cname }}</span></div>
                    <div class="list_lable">时间：<span>{{ item.date_start }}</span></div>
                    <div class="list_lable">订单数：<span>{{ item.order_num }}</span></div>
                    <div class="list_lable">收款金额：<span>{{ item.amount }}</span></div>
                    <div class="list_lable">计算方式：<span>{{ item.count_mode }}</span></div>
                    <div class="list_lable">金额：<span>{{ item.money }}</span></div>
                    <div class="list_lable">是否发放：<span>{{ item.status == 1 ? '已发放' : '未发放' }}</span></div>
            </div>
            <div class="btn_con">
                <template>
                    <a-button style="background:  #436DBB !important;" ghost @click="editOrder(item.status, item.id)">
                        <img src="@/assets/imgs/wap/edit.png" alt="">编辑</a-button>
                </template>
            </div>
        </div>
        <a-modal v-model="open" title="发放修改" @ok="handleOk">
            <a-radio-group v-model="status">
                <a-radio :style="radioStyle" :value="1">已发放</a-radio>
                <a-radio :style="radioStyle" :value="0">未发放</a-radio>
            </a-radio-group>
        </a-modal>
    </div>
</template>
<script>
// import $ from "jquery";
import '../../../assets/css/wapList.css';
import { requestXml, getDateTime } from '../../../assets/js/request';
export default {
    data() {
        return {
            // 搜索的内容
            name: "",
            orderStatus: ["取消", "新建", "进行中", "已完成"],
            data: [],
            title: this.$route.query.title,
            open: false,
            id: '',
            status: ''

        };
    },
    mounted: function () {
        this.getOrder();
    },
    methods: {
        handleOk() {
            requestXml("/scrm/staff/setPayrollStatus", "POST", (res) => {
                if (res == "success") {
                    this.getOrder();
                    this.open = false
                    this.status = ''
                    this.id = ''
                }
            }, { "id": this.id, 'status': this.status ? 1 : 0 })
        },
        // 获取订单列表
        getOrder() {
            requestXml("/scrm/staff/getPayrollList", "POST", (res) => {
                for (let i = 0; i < res.list.length; i++) {
                    res.list[i].created = getDateTime(res.list[i].created);
                    // res.list[i].date_start = getDateTime(res.list[i].date_start);
                    // res.list[i].date_end = getDateTime(res.list[i].date_end);
                    // res.list[i].status = res.list[i].status == 1 ? '已发放' : '未发放'
                    res.list[i].count_mode = res.list[i].count_mode == 1 ? '按单计算' : '按收款金额计算'
                    if (getDateTime(res.list[i].date_start) == getDateTime(res.list[i].date_end)) {
                        res.list[i].date_start = getDateTime(res.list[i].date_start);
                    } else {
                        res.list[i].date_start = getDateTime(res.list[i].date_start, 5)
                    }
                }
                this.data = res.list;
            }, { "name": this.name })
        },
        onSearch() {
            this.getOrder();
        },
        // 查看
        showDetail(id) {
            this.$router.push({
                path: "/scrm_wap/client/purchaseDetail",
                query: {
                    id: id,
                }
            });
        },
        // 编辑
        editOrder(status, id) {
            this.status = status
            this.id = id
            this.open = true
        }

    }
};
</script>

<style scoped>
.page {
    background-image: url('../../../assets/imgs/wap/bg.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% auto;
    padding-bottom: 50px;
}

/deep/.ant-input {
    background: #3056BF !important;
    color: #fff;
}
/deep/ .ant-input::placeholder,
/deep/ .ant-select::placeholder {
    color: #fff;
    font-size: 12px;
    /* 将占位符文本颜色设置为灰色 */
}

/deep/ .anticon svg,
/deep/ .ant-select svg {
    color: #fff;
}

.lists {
    padding: 0;
    border-radius: 20px;
}

.list_row {
    padding: 3rem;
}

.btn_con {
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.btn_con button {
    width: 30%;
    height: 40px;
    line-height: 18px;
    color: #fff;
    border-radius: 30px;
    border: none;
    font-size: 18px;
    margin: 0 auto;
    margin: 3rem;
}

.btn_con button img {
    height: 18px;
    vertical-align: middle;
    margin-right: 5px;
}

.btn_con1 {
    background: #FFF5E9;
    border-radius: 20px;
    padding: 10px 0;
    box-sizing: border-box;
}

.btn_con1 button {
    width: 100%;
    height: 30px;
    line-height: 18px;
    color: #FF9100;
    border-radius: 0;
    border: none;
    font-size: 18px;
}
.list_lable{
    color: #666666;
}
.list_lable span{
    color: #000000;
    float: right;
}
.barStyB{
    background: #00A200;
    border-radius: 20px 0px 20px 0px;
    color: #FFFFFF !important;
    font-weight: 200;
    display: inline-block;
    padding: 0 10px;
}
.barStyR{
    background: #436DBB;
    border-radius: 20px 0px 20px 0px;
    color: #FFFFFF !important;
    font-weight: 200;
    padding: 0 10px;
}
</style>
